import { defineStore, acceptHMRUpdate } from "pinia";

const useAuthStore = defineStore("ct:auth", () => {
  const api = useApi();
  const localePath = useLocalePath();
  const runtimeConfig = useRuntimeConfig();
  const { TTL_DAY } = useTTL();
  const { $sentrySetUser } = useNuxtApp();

  const isLoggedIn = ref<boolean>(false);

  const data = useState<SessionData | null>("auth:data", () => null);
  const getReferralLink = computed<string>(() => {
    if (data.value?.id) {
      const path = (localePath({ name: "buyout-type-manufacturer-meta_master-variant-color-quality", query: { refId: data.value.reference_code } }));
      return `${window.location.origin}/${runtimeConfig.public.region}${path}`;
    }
    return "";
  }
  );
  const hasInitialSession = computed<boolean>(() => !!data.value);
  const lastRefreshedAt = useState<Date | null>("auth:lastRefreshedAt", () => {
    if (hasInitialSession.value) {
      return /* @__PURE__ */ new Date();
    }
    return null;
  });
  const loading = useState<boolean>("auth:loading", () => false);
  const status = computed<string>(() => {
    if (loading.value) {
      return "loading";
    } else if (data.value) {
      return "authenticated";
    } else {
      return "unauthenticated";
    }
  });
  watch(status, (authenticated) => {
    isLoggedIn.value = authenticated === "authenticated";
  });
  const _accessTokenCookie = useCookie<string | null>(`auth:${runtimeConfig.public.region}:access-token`, { default: () => null, maxAge: TTL_DAY * 7, sameSite: "lax" });
  const accessToken = useState<string | null>(`auth:${runtimeConfig.public.region}:access-token`, () => _accessTokenCookie.value);

  const accessTokenHeader = computed<string | null>(() => {
    if (accessToken.value === null) {
      return null;
    }
    return `Bearer ${accessToken.value}`;
  });

  const isPartner = computed<boolean>(() => {
    return data.value?.role === "partner";
  });

  const isPremiumPartner = computed<boolean>(() => {
    return data.value?.is_premium_partner || false;
  });

  function parseJwt (token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
  }

  // const refreshToken = useState<string|null>("auth:refreshToken", () => _refreshTokenLs.value);
  // const refreshToken = useState<string|null>("auth:refreshToken", () => null);
  const _refreshTokenCookie = useCookie<string | null>(`auth:${runtimeConfig.public.region}:refresh-token`, { default: () => null, maxAge: TTL_DAY * 7, sameSite: "lax" });
  const refreshToken = useState<string | null>(`auth:${runtimeConfig.public.region}:refresh-token`, () => _refreshTokenCookie.value);

  watch(refreshToken, async (value) => {
    if (value !== null && value !== _refreshTokenCookie.value) {
      await nextTick();
      _refreshTokenCookie.value = value;
    }
  });

  function isAccessTokenExpired (): boolean {
    if (accessToken.value === null || accessToken.value === undefined) { return true; }
    return (Date.now() >= parseJwt(accessToken.value).exp * 1000);
  }

  async function refreshAccessToken (): Promise<boolean> {
    if (!refreshToken.value) {
      return false;
    }

    const { data: refreshData, error } = await useFetch<{access: string}>(
      api.commonUrls.auth.REFRESH_TOKEN(),
      { method: "POST", headers: { accept: "application/json" }, body: { refresh: refreshToken.value }, baseURL: api.baseUrl.value }
    );

    if (error.value) {
      if (error.value.statusCode === 401) {
        await internalLogout();
        return false;
      }
    }

    if (refreshData.value) {
      accessToken.value = refreshData.value.access;
      _accessTokenCookie.value = refreshData.value.access;
      if (!isLoggedIn.value) {
        await nextTick();
        await getSession();
        isLoggedIn.value = true;
      }
      return true;
    }
    return false;
  }

  async function getSession () {
    if (accessTokenHeader.value && !loading.value) {
      loading.value = true;
      if (isAccessTokenExpired()) {
        await refreshAccessToken();
      }
      const { data: sessionData, error } = await useFetch<SessionData>(api.commonUrls.auth.SESSION(), {
        headers: { Authorization: accessTokenHeader.value, accept: "application/json", "X-Region": runtimeConfig.public.region },
        baseURL: api.baseUrl.value
      });

      if (sessionData.value) {
        data.value = sessionData.value;
        $sentrySetUser({ id: sessionData.value.id, username: sessionData.value.username, email: sessionData.value.email });
      }
      if (error.value) {
        data.value = null;
        accessToken.value = null;
        _accessTokenCookie.value = null;
      }
      loading.value = false;
      lastRefreshedAt.value = /* @__PURE__ */ new Date();
    }
  }

  async function internalLogout () {
    isLoggedIn.value = false;
    await nextTick();
    accessToken.value = null;
    _accessTokenCookie.value = null;
    data.value = null;
    refreshToken.value = null;
    _refreshTokenCookie.value = null;
    await nextTick();
  }

  /**
   * This function must be used instead of useAuth().signOut() because the user's refresh token must be
   * sent to be blacklisted.
   */
  async function logout (name: string = "index") {
    if (accessTokenHeader.value !== null) {
      if (isAccessTokenExpired()) {
        await refreshAccessToken();
      }
      await useFetch(
        api.commonUrls.auth.LOGOUT(),
        {
          method: "POST",
          headers: { Authorization: accessTokenHeader.value, accept: "application/json", "X-Region": runtimeConfig.public.region },
          body: { refresh_token: refreshToken.value },
          baseURL: api.baseUrl.value
        }
      );
    }
    await internalLogout();
    $sentrySetUser(null);
    await nextTick();
    navigateTo(useLocalePath()({ name }));
  }

  function hasPermission (permission: string): boolean {
    if (!data.value) { return false; }
    return data.value.permissions.includes(permission);
  }

  // refresh the session on page load (resp. on store installation)
  // if (process.client || process.server) {
  //   if (refreshToken.value && isAccessTokenExpired()) {
  //     refreshAccessToken().then(async (refreshed) => {
  //       if (refreshed) {
  //         await nextTick(getSession);
  //       }
  //     });
  //   } else if (refreshToken.value && !data.value) {
  //     nextTick(getSession);
  //   }
  // }

  return {
    refreshToken,
    data,
    lastRefreshedAt,
    isLoggedIn,
    loading,
    status,
    accessToken,
    _accessTokenCookie,
    accessTokenHeader,
    isPartner,
    isPremiumPartner,
    isAccessTokenExpired,
    refreshAccessToken,
    logout,
    getSession,
    getReferralLink,
    hasPermission
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export default useAuthStore;
