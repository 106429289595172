export default defineNuxtRouteMiddleware((to) => {
  const { $i18n: i18n } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  const regionLanguageMap = {
    cz: ["cs", "en"],
    sk: ["sk", "en"]
  };

  const region = runtimeConfig.public.region as keyof typeof regionLanguageMap;
  const regionLanguages: string[] = region && region in regionLanguageMap
    ? regionLanguageMap[region]
    : [];
  const defaultRegionLanguage = regionLanguages[0];
  const oldLocale = i18n.locale.value;

  if (oldLocale && !regionLanguages.includes(oldLocale)) {
    const regexp = new RegExp(`^/${oldLocale}/`, "i");
    const newPath = to.fullPath.replace(regexp, `/${defaultRegionLanguage}/`);
    i18n.locale.value = defaultRegionLanguage;
    if (newPath === "/") {
      return navigateTo(`${runtimeConfig.public.baseUrl}/${defaultRegionLanguage}/`, { external: true });
    }
    return navigateTo(newPath, { replace: true });
  }
});
