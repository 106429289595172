<template>
  <main class="app main--error flex-center flex-column text-center">
    <picture>
      <source
        srcset="~./assets/media/ambassador-program-3@2x.webp 2x, ~./assets/media/ambassador-program-3.webp 1x"
        type="image/webp"
      >
      <source srcset="~./assets/media/ambassador-program-3@2x.jpg 2x" type="image/jpeg">
      <img
        src="~./assets/media/ambassador-program-3.jpg"
        width="544"
        height="402"
        alt="cashtec - něco se pokazilo"
        class="img-responsive"
        loading="lazy"
      >
    </picture>
    <img src="~./assets/svg/cashtec-logo.svg?url" class="icon icon--cashtec my-5" alt="CashTec logo">
    <div class="app__title app__title--h3 font-poppins mb-3">
      <template v-if="error.statusCode === 401">
        {{ $t("general.error.401") }}
      </template>
      <template v-else-if="error.statusCode === 404">
        {{ $t("general.error.404") }}
      </template>
      <template v-else-if="error.statusCode === 500">
        {{ $t("general.error.500") }}
      </template>
      <template v-else-if="error.statusCode === 502">
        {{ $t("general.error.502") }}
      </template>
      <template v-else>
        {{ $t("general.error.else") }}
      </template>
    </div>
    <div class="app__label font-poppins mb-10 color--grayTone4 font-weight-regular">
      {{ $t("general.error.return_to_the_home_page") }}
    </div>
    <v-btn
      class="mbtn mbtn--green"
      size="x-large"
      @click="handleError"
    >
      {{ $t("general.error.back_to_the_home_page") }}
    </v-btn>
  </main>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: Record<string, unknown>
}>();

const localePath = useLocalePath();
const route = useRoute();
const { locales } = useI18n();

function startsWithLanguage (path: string, prefixes: string[]): boolean {
  return prefixes.some(prefix => path.startsWith(prefix));
}

if (route.redirectedFrom === undefined && props.error.statusCode === 404) {
  const formattedLocales = ref<[]>([]);

  locales.value.forEach((lang) => {
    if (lang) {
      formattedLocales.value.push(`/${lang.code}/`);
    }
  });

  if (!startsWithLanguage(route.path, formattedLocales.value)) {
    navigateTo(localePath({ path: route.path, query: route.query }), { replace: true, redirectCode: 301 });
  }
}

function handleError () {
  clearError({ redirect: localePath({ name: "index" }) });
}
</script>
