import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/src/middleware/auth.global.ts";
import lang_45guard_45global from "/app/src/middleware/lang_guard.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  lang_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-partner": () => import("/app/src/middleware/is_partner.ts"),
  "is-premium-partner": () => import("/app/src/middleware/is_premium_partner.ts")
}